$(function(){

    //-------------------------
    //SVGスプライト
    //-------------------------
    $.ajax({
        type: 'get',
        url: '/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
    }).done(function(data) {
        var svg = $(data).find('svg');
        $('body').prepend(svg);
    });

    if ($('#flashMessage').length > 0) {
        $('#flashMessage').delay(2000).slideUp();
    }

    //-------------------------
    //ページトップ
    //-------------------------
    /*ページトップ指定*/
    var pagetop = $('.pagetop');
    /* ページトップアクション */
    pagetop.click(function () {
        $('body, html').animate({ scrollTop: 0 }, 500);
        return false;
    });

    //-------------------------
    //ドロワー
    //-------------------------
    var menuBtn = $('.drawer-toggle');
    var body = $('body');
    var layer = $('.js_layer');
    $(menuBtn).on('click', function(e) {
        if($(this).is('.open')) {
            $(this).add(body).removeClass('open');
        } else {
            $(this).add(body).addClass('open');
        }
        e.preventDefault();
    });
    $(layer).on('click' , function(e) {
        $(menuBtn).add(body).removeClass('open');
        $(layer).removeClass('on');
        e.preventDefault();
    });

    //-------------------------
    //メニューアコーディオン
    //-------------------------
    $(".togggle_content").hide();
    $(".togggle_btn").on('click', function(e) {
        $(this).toggleClass("active");
        $(this).next().slideToggle();
        $(this).next().toggleClass("active");
        e.preventDefault();
        console.log("hoge");
    });

    //-------------------------
    //グロナビの中のドロップダウンメニュー
    //-------------------------
    $(".header_gnav .community_parent").hover(function() {
        $(this).addClass('active');
        // $(this).children('ul').show();
    }, function() {
        $(this).removeClass('active');
        // $(this).children('ul').hide();
        console.log('test');
    });

    //-------------------------
    //フォントサイズ変更
    //-------------------------
    $(".font-size-change li").click(function(){
        $(".font-size-change li").removeClass("current");
        $("html").removeClass("large");
        $("html").removeClass("middle");
        $("html").removeClass("small");
        var fontCss = $(this).attr("class");
        $(this).addClass("current");
        
        if(fontCss == "large"){
            $("html").addClass("large"); 
        }else if(fontCss == "middle"){
            $("html").addClass("middle");
        }else{
            $("html").addClass("small");
        }
    });

});



//-------------------------
//スマホかの確認
//-------------------------
function isPhone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
}

$(function() {

    //-------------------------
    //スマホのみのプログラム
    //-------------------------
    if (!isPhone())
        return;

        //-------------------------
        //-------------------------
        //スマートフォン時に電話をかける
        //-------------------------
        $('.js-call').each(function() {
            var tel = $(this).data('tel');
            $(this).attr('href', 'tel:' + tel);
        });

        //-------------------------
        //ヘッダーメニュー
        //-------------------------
        var nav = $('.scroll_header');
        //表示位置
        var navTop = nav.offset().top+50;
        //ナビゲーションの高さ（シャドウの分だけ足してます）
        var navHeight = nav.height()+30;
        var showFlag = false;
        nav.css('top', -navHeight+'px');
        //ナビゲーションの位置まできたら表示
        $(window).scroll(function () {
            var winTop = $(this).scrollTop();
            if (winTop >= navTop) {
                if (showFlag == false) {
                    showFlag = true;
                    nav
                        .addClass('fixed')
                        .stop().animate({'top' : '0px'}, 200);
                }
            } else if (winTop <= navTop) {
                if (showFlag) {
                    showFlag = false;
                    nav.stop().animate({'top' : -navHeight+'px'}, 200, function(){
                        nav.removeClass('fixed');
                    });
                }
            }
        });

        //-------------------------
        //フッターメニュー
        //-------------------------
        $(window).on("load",function(){
            $('.header_gnav li').tile();
        });

        //-------------------------
        //バナースライドショー
        //-------------------------
        $('.bnr-slider').sliderPro({
            width: 280,//横幅
            height:156,
            visibleSize: '100%',//前後のスライドを表示
            arrows:true
        });

});
